import axios from 'axios';
import autocomplete from 'autocomplete.js';
import ApplicationController from "./application_controller";

const CITY_TYPE = 'city';
const OK_STATUS = 'OK';

export default class extends ApplicationController {
  static targets = [
    'originCity',
    'hiddenOriginCity',
    'destinationCity',
    'hiddenDestinationCity',
    'markupRuleTable',
    'markupRuleTableContainer',
    'routeMarkupRuleTable',
    'routeRulePercentage',
    'routeRuleAmount',
    'routeMarkupRuleTableRow'
  ];

  connect() {
    this.addAutocomplete(this.originCityTarget, this.hiddenOriginCityTarget);
    this.addAutocomplete(this.destinationCityTarget, this.hiddenDestinationCityTarget);
    this.rules = JSON.parse(this.markupRuleTableContainerTarget.dataset.rules);
    this.renderTable();
  }

  source() {
    const url = window.PLACES_URL;
    return (q, callback) => {
      axios.get(url, {params: {q}}).then((response) => {
        callback(response.data.filter(({result_type}) => result_type === 'city'));
      });
    };
  }

  addAutocomplete(inputTarget, hiddenTarget) {
    const ac = autocomplete(inputTarget, {hint: false}, [
      {
        source: this.source(),
        debounce: 200,
        templates: {
          suggestion: function (suggestion) {
            return `${suggestion.display}, ${CITY_TYPE === 'city' ? suggestion.state : suggestion.city_name}`;
          },
        },
      },
    ]).on('autocomplete:selected', (event, suggestion, dataset, context) => {
      ac.autocomplete.setVal(suggestion.display);
      hiddenTarget.value = suggestion.slug;
    });
  }

  updateDefaultRule(event) {
    const [xhr, _status] = event.detail

    if (_status === OK_STATUS) {
      alert('Se ha actualizado exitosamente el markup.');
    } else {
      alert(this.extractMessages(xhr));
    }
  }

  extractMessages(xhr) {
    const response = JSON.parse(xhr.response);
    let messages = 'No se pudo completar la operación:\n\n';
    messages += Object.values(response).reduce((accumulator, currentValue) => accumulator.concat(currentValue[0], '\n'), '');
    return messages;
  }

  createRouteRule(event) {
    const [xhr, _status] = event.detail;

    if (_status === OK_STATUS) {
      const response = JSON.parse(xhr.response);
      this.rules = [...this.rules, response];
      this.removeTable();
      this.renderTable();

      this.originCityTarget.value = '';
      this.hiddenOriginCityTarget.value = '';
      this.destinationCityTarget.value = '';
      this.hiddenDestinationCityTarget.value = '';
      this.routeRulePercentageTarget.value = '';
      this.routeRuleAmountTarget.value = '';
    } else {
      alert(this.extractMessages(xhr));
    }
  }

  removeTable() {
    this.markupRuleTableContainerTarget.removeChild(this.markupRuleTableContainerTarget.firstElementChild);
  }

  deleteRouteRule(event) {
    const [xhr, _status] = event.detail;

    if (_status === OK_STATUS) {
      const response = JSON.parse(xhr.response);
      this.rules = this.rules.filter(rule => rule.slug !== response.slug);
      this.removeTable();
      this.renderTable();
    } else {
      alert(this.extractMessages(xhr));
    }
  }

  renderTable() {
    // table row array
    const trs = this.rules.map(rule => this.makeTr(rule));
    const table = this.routeMarkupRuleTableTarget.content.cloneNode(true);
    const tbody = table.querySelector('tbody');
    trs.forEach(tr => tbody.appendChild(tr));
    this.markupRuleTableContainerTarget.appendChild(table);
  }

  /**
   * Make tr element for the markup rule table.
   * @param rule - rule data.
   * @returns {HTMLTableRowElement}
   */
  makeTr(rule) {
    const {
      origin_city: {city: originCity},
      destination_city: {city: destinationCity},
      description,
      slug
    } = rule;
    // table row element
    const tr = this.routeMarkupRuleTableRowTarget.content.cloneNode(true);
    const [originCityTd, destinationCityTd, descriptionTd, deleteTd] = tr.firstElementChild.children;
    // anchor element
    const a = deleteTd.firstElementChild;

    originCityTd.innerText = originCity;
    destinationCityTd.innerText = destinationCity;
    descriptionTd.innerText = description;
    a.setAttribute('href', `/markup_rules/${slug}/expire`);

    return tr;
  }
}
